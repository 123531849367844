import React from 'react';
import { LoadingPage } from '../../pages/Loading/LoadingPage';
import { AnimatedWrapper } from '../../components/AnimatedWrapper/AnimatedWrapper';

interface SectionLayoutPros {
  children?: React.ReactNode;
  isLoading?: boolean;
  className?: string;
}

export const SectionLayout: React.FC<SectionLayoutPros> = ({
  children,
  className,
  isLoading,
}) => {
  if (isLoading) {
    return <LoadingPage />;
  }
  return <AnimatedWrapper className={className}>{children}</AnimatedWrapper>;
};
