import React from 'react';
import notFoundImg from '../../assets/images/not-found-image.png';
import { Link } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

export const ErrorPage: React.FC = () => {
  const pageNotFoundTitle = 'Page not found';

  useDocumentTitle(pageNotFoundTitle);

  return (
    <section className=" mkt-w-full mkt-h-[802px] mkt-flex mkt-flex-col mkt-justify-center mkt-items-center mkt-gap-8 lg:mkt-flex-row mkt-px-3 lg:mkt-px-5">
      <article className="mkt-flex mkt-flex-col mkt-gap-[40px] mkt-order-2 lg:mkt-order-1 mkt-items-center lg:mkt-items-start">
        <div className="mkt-flex mkt-flex-col mkt-gap-4 mkt-text-center lg:mkt-text-start">
          <div>
            <h1 className="mkt-font-sans mkt-text-neutral-900 mkt-text-[48px]">
              Error 404
            </h1>
            <p className="mkt-font-sans mkt-text-neutral-700 mkt-text-[32px] mkt-font-light">
              We can’t find the page you’re looking for!
            </p>
          </div>
          <p className="mkt-text-[20px] mkt-text-neutral-500">
            Please navigate back or explore other sections of our website. Thank
            you!
          </p>
        </div>

        <Link
          to={'/'}
          className="mkt-w-[269px] mkt-h-[59px] mkt-px-4 mkt-py-1 mkt-bg-blue-dark mkt-text-white mkt-rounded-[6px] mkt-text-[20px] mkt-flex mkt-justify-center mkt-items-center hover:mkt-brightness-90 mkt-transition-all"
        >
          Go to Homepage
        </Link>
      </article>
      <img
        src={notFoundImg}
        alt="no page found img"
        className="mkt-block mkt-w-[300px] mkt-h-[300px]mkt-object-contain mkt-order-1 lg:mkt-order-2"
      />
    </section>
  );
};
