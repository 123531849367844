import { Builder } from '@builder.io/react';
import { Component } from '@builder.io/sdk';
import { MktPopupForm } from '@securitize/reactjs-mkt-ui';
import { BuilderComponents } from '../../helpers/constants';

const PopupFormBuilderConfig: Component = {
  name: BuilderComponents.POPUP_FORM,
  inputs: [
    {
      name: 'title',
      type: 'string',
      required: true,
    },
    {
      name: 'subtitle',
      type: 'string',
      required: true,
    },
    {
      name: 'hubSpotFormId',
      type: 'string',
      required: true,
    },
    {
      name: 'hubSpotRegionId',
      type: 'string',
      required: true,
    },
    {
      name: 'hubSpotPortalId',
      type: 'string',
      required: true,
    },
    {
      name: 'isVisible',
      type: 'boolean',
      required: true,
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png'],
    },
  ],
};

Builder.registerComponent(MktPopupForm, PopupFormBuilderConfig);
