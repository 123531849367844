import { Builder } from '@builder.io/react';
import { Component } from '@builder.io/sdk';
import { MktAccordion } from '@securitize/reactjs-mkt-ui';
import { BuilderComponents } from '../../helpers/constants';

const AccordionBuilderConfig: Component = {
  name: BuilderComponents.ACCORDION,
  image:
    'https://cdn.builder.io/api/v1/image/assets%2Fd39b51a544e84e2fbb2445f58c6c6f2c%2F75f4019a88794aef9e62e1ba5eac736f?width=500&height=500',
  inputs: [
    {
      name: 'tabs',
      friendlyName: 'Tabs',
      type: 'list',
      required: true,
      defaultValue: [
        {
          title: 'My title',
          description: 'My description',
          image:
            'https://cdn.builder.io/api/v1/image/assets%2Fd39b51a544e84e2fbb2445f58c6c6f2c%2Fee926590bf6d46228d71deceb691373f',
          redirectUrl: 'https://securitize.io/',
        },
      ],
      subFields: [
        {
          name: 'title',
          friendlyName: 'Title',
          type: 'string',
          required: true,
        },
        {
          name: 'description',
          friendlyName: 'Description',
          type: 'string',
          required: true,
        },
        {
          name: 'image',
          friendlyName: 'Image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'png'],
          required: false,
        },
        {
          name: 'redirectUrl',
          friendlyName: 'Redirect URL',
          type: 'string',
          required: false,
        },
      ],
    },
    {
      name: 'reverse',
      friendlyName: 'Reverse',
      type: 'boolean',
      required: false,
    },
    {
      name: 'variant',
      friendlyName: 'Variant Color',
      type: 'string',
      defaultValue: 'dark',
      enum: ['light', 'dark'],
      required: true,
    },
    {
      name: 'disabledMobileImage',
      friendlyName: 'Disable image on mobile',
      defaultValue: false,
      type: 'boolean',
      required: false,
      advanced: true,
    },
  ],
};

Builder.registerComponent(MktAccordion, AccordionBuilderConfig);
