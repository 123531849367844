export const configs = {
  BUILDER_PUBLIC_API_KEY: process.env.REACT_APP_BUILDER_PUBLIC_API_KEY || '',
  SID_WEBSITE_BASE_URL: process.env.REACT_APP_SID_WEBSITE_BASE_URL || '',
};

export enum BuilderModels {
  // Page models
  PAGE = 'landing-page',
  // Footer
  FOOTER_LINK = 'landing-footer-link',
  // Topbar
  TOP_BAR_LINK = 'landing-topbar-link',
}

export enum BuilderComponents {
  // Accordion
  ACCORDION = 'Accordion',
  // Fqs Accordion
  FAQS_ACCORDION = 'Faqs Accordion',
  // PopUp Form
  POPUP_FORM = 'Popup Form',
  // Button
  BUTTON = 'Button',
  // Market Car
  MARKET_CARD = 'Market Card',
}

export const footerConstants = {
  COPYRIGHT: '© 2024 Securitize LLC',
  EMAIL_CONTACT: 'info@securitize.io',
};
