import React from 'react';
import { MktFooter } from '@securitize/reactjs-mkt-ui';
import { FooterLinkModel } from '../../data/builderio/footer/footer-link.model';
import { footerConstants } from '../../helpers/constants';
import secNegative from '@securitize/assets-sec-ui/dist/logos/svg/sec-logo-negative.svg';
import youtubeIcon from '@securitize/assets-mkt-ui/dist/social/svg/youtube-social.svg';
import twitterIcon from '@securitize/assets-mkt-ui/dist/social/svg/twitter-social.svg';
import linkedinIcon from '@securitize/assets-mkt-ui/dist/social/svg/linkedin-social.svg';

interface FooterProps {
  footerLinks: FooterLinkModel[];
}

export const Footer: React.FC<FooterProps> = ({ footerLinks }) => {
  const navigationLinks: any[] =
    footerLinks &&
    footerLinks?.map(({ data }: any, index) => {
      return {
        title: data.label,
        index,
        links: data.links?.map((link: any, index: number) => {
          return {
            label: link.label,
            url: link.url,
            index,
          };
        }),
      };
    });

  const socialLinks = [
    {
      icon: linkedinIcon,
      url: 'https://www.linkedin.com/company/securitize/',
      title: 'Linkedin',
    },
    {
      icon: twitterIcon,
      url: 'https://twitter.com/Securitize?utm_source=Markets&utm_medium=referral',
      title: 'Twitter',
    },
    {
      icon: youtubeIcon,
      url: 'https://www.youtube.com/c/Securitize/featured?utm_source=Markets&utm_medium=referral',
      title: 'Youtube',
    },
  ];

  return (
    <MktFooter
      navigationLinks={navigationLinks}
      showDisclaimer={true}
      footerLogo={secNegative}
      showSocialAndAppStoreLinks={true}
      emailContact={footerConstants.EMAIL_CONTACT}
      copyrightInfo={footerConstants.COPYRIGHT}
      socialLinks={socialLinks}
    />
  );
};

export const MemoizedFooter = React.memo(Footer);
