import builder from '@builder.io/react';
import { BuilderModels } from '../../../helpers/constants';
import { TopBarLinkModel } from './top-bar-link.model';

export const getTopBarLinks = async (): Promise<TopBarLinkModel[]> => {
  return builder.getAll(BuilderModels.TOP_BAR_LINK, {
    fields: 'data',
    options: { noTargeting: true },
  });
};
