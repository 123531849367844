import { INavigationRoutes } from '../global/types/navigation.types';

// Builder wildcard Page
import { Page } from '../pages/Page';

// Navigation Routes to map in router
export const navigationRoutes: INavigationRoutes[] = [
  {
    path: '*',
    Component: Page,
  },
];
