import './helpers/builder-settings';
import './i18n/config';
import { BrowserRouter } from 'react-router-dom';
import { AnimatedRoutes } from './routes/AnimatedRoutes';

function App() {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
}

export default App;
