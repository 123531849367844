import React from 'react';
import { MktMarketCard as MarketCard } from '@securitize/reactjs-mkt-ui';
import { Link } from 'react-router-dom';

interface MktMarketCardProps {
  title: string;
  subtitle: string;
  description?: string;
  tableValue: { key: string; value: string; link?: string }[];
  image: string;
  linkUrl?: string;
  tags: { children: string }[];
  backgroundImage?: string;
}

export const MktMarkedCard: React.FC<MktMarketCardProps> = ({
  image,
  linkUrl,
  subtitle,
  tableValue,
  title,
  description,
  tags,
  backgroundImage,
}) => {
  if (linkUrl) {
    return (
      <div className="market-card">
        <Link to={linkUrl}>
          <MarketCard
            tags={tags}
            logo={image}
            subtitle={subtitle}
            tableValues={tableValue}
            title={title}
            description={description}
            backgroundImage={backgroundImage}
          />
        </Link>
      </div>
    );
  }

  return (
    <div className="market-card">
      <MarketCard
        tags={tags}
        logo={image}
        subtitle={subtitle}
        tableValues={tableValue}
        title={title}
        description={description}
        backgroundImage={backgroundImage}
      />
    </div>
  );
};
