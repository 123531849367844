/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { LoadingPage } from '../../pages/Loading/LoadingPage';
import { MemoizedFooter } from '../../components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { SectionLayout } from '../SectionLayout/SectionLayout';
import { ErrorPage } from '../../pages/Error/ErrorPage';
import clsx from 'clsx';
import { scrollToTop } from '../../helpers/utils-helpers';
import { TopBar } from '../../components/TopBar/TopBar';
import { usePageStore } from '../../store/page/page.store';

interface SectionLayoutProps {
  children?: React.ReactNode;
  className?: string;
}

export const PageLayout: React.FC<SectionLayoutProps> = ({
  children,
  className,
}) => {
  const currentUrlPath = useLocation().pathname;

  const {
    content,
    footerLinks,
    getFooterLinks,
    getPageContent,
    getTopbarLinks,
    isFooterLoading,
    isPageLoading,
    isTopBarLoading,
    topBarLinks,
  } = usePageStore();

  useEffect(() => {
    scrollToTop();
  }, [currentUrlPath]);

  const securitizeUrl = 'https://securitize.io/';

  useEffect(() => {
    if (currentUrlPath === '/') {
      window.location.replace(securitizeUrl);
    } else {
      getPageContent(currentUrlPath);
    }
  }, [currentUrlPath]);

  useEffect(() => {
    Promise.all([getTopbarLinks(), getFooterLinks()]);
  }, []);

  if (isFooterLoading || isTopBarLoading) {
    return <LoadingPage />;
  }

  const isPageUnavailable = !content && !isPageLoading;

  if (isPageUnavailable) {
    return (
      <main className={className}>
        <TopBar
          topBarLinks={topBarLinks}
          transparentVariant="dark"
          fixedVariant={'dark'}
        />
        <ErrorPage />
        <MemoizedFooter footerLinks={footerLinks} />
      </main>
    );
  }

  return (
    <main>
      <div className={className} style={{ position: 'relative' }}>
        <div className={clsx('mkt-sticky mkt-top-0 mkt-left-0 mkt-z-[80000]')}>
          <TopBar
            topBarLinks={topBarLinks}
            variant={content?.data?.topbarVariant ?? 'dark'}
            transparentVariant={
              content?.data?.topbarTransparentVariant ?? 'dark'
            }
            fixedVariant={null}
          />
        </div>

        <SectionLayout
          className={clsx('mkt-mt-[-88px] ')}
          isLoading={isPageLoading}
        >
          {children}
        </SectionLayout>
        <MemoizedFooter footerLinks={footerLinks} />
      </div>
    </main>
  );
};
