import { Builder } from '@builder.io/react';
import { BuilderComponents } from '../../helpers/constants';
import { MktMarkedCard } from './MarketCard';

Builder.registerComponent(MktMarkedCard, {
  name: BuilderComponents.MARKET_CARD,
  inputs: [
    { name: 'title', type: 'text' },
    { name: 'subtitle', type: 'text' },
    { name: 'description', type: 'text' },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png'],
      require: true,
    },
    {
      name: 'backgroundImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png'],
      require: false,
    },
    {
      name: 'linkUrl',
      type: 'text',
      require: false,
    },
    {
      name: 'tags',
      type: 'list',
      subFields: [
        {
          name: 'children',
          type: 'string',
        },
        {
          name: 'color',
          friendlyName: 'Market Card Tag Color',
          type: 'string',
          defaultValue: 'dark-brown',
          enum: ['dark-brown', 'twig', 'mauve', 'taupe', 'ash', 'light-grey'],
        },
      ],
    },
    {
      name: 'tableValue',
      type: 'list',
      subFields: [
        {
          name: 'key',
          type: 'string',
        },
        {
          name: 'value',
          type: 'string',
        },
      ],
    },
  ],
});
