import { Builder } from '@builder.io/react';
import { BuilderComponents } from '../../helpers/constants';
import { MktButton } from '@securitize/reactjs-mkt-ui';

Builder.registerComponent(MktButton, {
  name: BuilderComponents.BUTTON,
  inputs: [
    { name: 'children', type: 'text' },
    {
      name: 'variant',
      type: 'text',
      enum: ['primary', 'secondary-light', 'secondary-dark', 'light'],
    },
  ],
});
