import builder from '@builder.io/react';
import { BuilderModels } from '../../../helpers/constants';
import { BuilderContent } from '@builder.io/sdk';

export const getPageByUrl = async (url: string): Promise<BuilderContent> => {
  return builder
    .get(BuilderModels.PAGE, {
      url,
    })
    .promise();
};
