import builder from '@builder.io/react';
import { BuilderModels } from '../../../helpers/constants';
import { FooterLinkModel } from './footer-link.model';

export const getFooterLinks = async (): Promise<FooterLinkModel[]> => {
  return builder.getAll(BuilderModels.FOOTER_LINK, {
    fields: 'data',
    options: { noTargeting: true },
  });
};
